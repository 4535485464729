<template>
  <VChip
    :class="{ 'chip-truncate': textTruncate }"
    :color="color"
    :disabled="disabled"
    :label="label"
    :pill="pill"
    :prepend-icon="prependIcon"
    :size="size"
    :text="text"
    :variant="variant"
  >
    <template v-if="prependSlotDefined" #prepend>
      <slot name="prepend" />
    </template>

    <template v-if="defaultSlotDefined" #default>
      <slot />
    </template>
  </VChip>
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{
    text?: string
    color?: string
    label?: boolean
    size?: 'x-small' | 'small' | 'default' | 'large' | 'x-large'
    variant?: 'flat' | 'text' | 'elevated' | 'tonal' | 'outlined' | 'plain'
    textTruncate?: boolean
    prependIcon?: string
    pill?: boolean
    disabled?: boolean
  }>(),
  {
    text: undefined,
    color: undefined,
    label: false,
    size: 'default',
    variant: 'tonal',
    textTruncate: false,
    prependIcon: undefined,
    pill: false,
    disabled: false,
  }
)

const slots = useSlots()
const defaultSlotDefined = slots.default !== undefined
const prependSlotDefined = slots.prepend !== undefined
</script>

<style scoped>
.chip-truncate {
  margin: 2px;
}
.chip-truncate :deep(.v-chip__content) {
  display: inline-block !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
